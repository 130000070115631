const faqs = [
  {
    question: "¿Qué se necesita para viajar a Italia desde México?",
    answer: (
      <div>
        <p>-  Pasaporte válido: Asegúrate de que tu pasaporte esté vigente durante todo el período de tu estancia en Italia. Es recomendable que tenga una validez mínima de seis meses a partir de la fecha de entrada al país. Si tu pasaporte está a punto de vencer, tramita uno nuevo con suficiente antelación.</p><br/>
        <p>- Visa Schengen: Los ciudadanos mexicanos no requieren visa para estancias turísticas de hasta 90 días dentro de un período de 180 días en el territorio Schengen, que incluye a Italia. Sin embargo, es fundamental que verifiques la política vigente antes de viajar, ya que los requisitos pueden cambiar.</p><br/>
        <p>- Boleto de ida y vuelta: Al ingresar a Italia, es probable que las autoridades de inmigración te pidan mostrar el boleto de regreso a México o a tu próximo destino. Asegúrate de tener este documento impreso o disponible electrónicamente antes de tu partida.</p><br/>
        <p>- Comprobante de alojamiento: Es aconsejable llevar contigo una confirmación de reserva de hotel o una carta de invitación de un anfitrión en Italia, donde se indique claramente la dirección donde te hospedarás durante tu estancia.</p><br/>
        <p>- Dinero suficiente: Es prudente llevar suficiente dinero en efectivo o tarjetas de crédito válidas para cubrir tus gastos durante la estancia en Italia. Asegúrate de informar a tu banco sobre tus planes de viaje para evitar bloqueos de tarjetas por transacciones internacionales.</p><br/>
        <p>- Documentación adicional: Puede que se te solicite presentar documentos adicionales al llegar a Italia, como prueba de empleo o estudios, itinerario de viaje detallado, entre otros. Aunque es posible que no te los pidan, es conveniente tenerlos a mano por si acaso.</p><br/>
    
      </div>
    ),
  },

 
];

export default faqs;
